import { difference, get, map } from 'lodash-es';

import {
  DIRECTORY_HIERARCHY_DELIMITER,
  MAX_SELECTED_FILENAME_PATTERNS,
  PATTERN_MAX_MATCHED_FILES,
  PATTERN_MAX_TOTAL_FILE_SIZE,
  PATTERN_PLACEHOLDER_PREFIX,
} from '~/support/constants';

export default {
  allPatterns(state) {
    return [...state.customPatterns, ...map(state.foundPatterns, 'filenamePattern')];
  },

  allPatternsSelected(state, getters) {
    return (
      Boolean(state.selectedPatterns.length) && difference(getters.allPatterns, state.selectedPatterns).length === 0
    );
  },

  customPatternsFolderPaths(state) {
    const folderPaths = [];
    const patternsWithFolders = state.customPatterns.filter((item) => item.includes(DIRECTORY_HIERARCHY_DELIMITER));

    patternsWithFolders.forEach((pattern) => {
      const tokens = pattern.split(PATTERN_PLACEHOLDER_PREFIX);

      folderPaths.push(tokens[0].split(DIRECTORY_HIERARCHY_DELIMITER)[0]);
    });

    return folderPaths;
  },

  foundPatternsBasePaths(state) {
    const foundPatterns = state.foundPatterns.map((foundPattern) => foundPattern.filenamePattern);

    return foundPatterns.map((pattern) => {
      const tokens = pattern.split(DIRECTORY_HIERARCHY_DELIMITER);

      tokens.splice(-1);

      return tokens.join(DIRECTORY_HIERARCHY_DELIMITER);
    });
  },

  maxSelectedFilenamePatternsExceeded(state) {
    return state.selectedPatterns.length > MAX_SELECTED_FILENAME_PATTERNS;
  },

  patternData(state) {
    return (pattern) => get(state.matchingFiles, pattern, {});
  },

  patternFiles(state, getters) {
    return (pattern) => getters.patternData(pattern).files || [];
  },

  patternTotalArchivesOverSizeLimit(state, getters) {
    return (pattern) => getters.patternData(pattern).totalArchivesOverSizeLimit;
  },

  patternTotalFiles(state, getters) {
    return (pattern) => getters.patternData(pattern).totalFiles || 0;
  },

  patternTotalFilesOverSizeLimit(state, getters) {
    return (pattern) => getters.patternData(pattern).totalFilesOverSizeLimit;
  },

  patternTotalFilesSize(state, getters) {
    return (pattern) => getters.patternData(pattern).totalSizeOfFiles || 0;
  },

  shouldDisableModelDataButton(state, getters) {
    return (
      !state.selectedPatterns.length ||
      getters.maxSelectedFilenamePatternsExceeded ||
      state.selectedPatterns.some(
        (pattern) =>
          getters.patternTotalFilesSize(pattern) > PATTERN_MAX_TOTAL_FILE_SIZE ||
          getters.patternTotalFiles(pattern) > PATTERN_MAX_MATCHED_FILES ||
          getters.patternTotalFilesOverSizeLimit(pattern) ||
          getters.patternTotalArchivesOverSizeLimit(pattern),
      )
    );
  },
};
