import { isValidCron } from 'cron-validator';
import { toString as cronstrueToString } from 'cronstrue';
import { every, isInteger, range, sortBy, split, take, toString } from 'lodash-es';

import { useFeatureFlags } from '~/support/composables/feature-flags';
import {
  CRON_ANY_VALUE,
  CRON_DAY_VALUES,
  CRON_END_TIME_OFFSET,
  CRON_PART_MAX_VALUE,
  CRON_PART_MAX_VALUE_NEXT,
  CRON_SECOND_DEFAULT,
  CRON_TIMEZONE,
  NON_INTRA_DAY_EXPRESSIONS,
  HEATMAP_X_AXIS,
  HEATMAP_Y_AXIS,
  CRON_PARTS_COUNT,
  CRONS_PARTS_COUNT_NEXT,
  CRON_PART_INDEX,
  CRON_PART_INDEX_NEXT,
  CRON_SPECIAL_EXPRESSIONS,
  CRON_SPECIAL_DESCRIPTIONS,
} from '~/support/constants';

export default {
  /*
    we are building a custom cron with minute intervals, this results in the end hour
    extending by 59 minutes (12-2:59). This method will offset that added time (ex. 12-2).
    If the form capabilities are expanded in the future this will need to be modified.
  */
  buildCronHours(startTime, endTime) {
    const offsetEndTime = endTime - CRON_END_TIME_OFFSET;

    if (!endTime || startTime === offsetEndTime) return startTime;

    return `${startTime}-${offsetEndTime}`;
  },

  buildDeadlineCronExpression(deadline) {
    const cronParts = {
      dayOfMonth: deadline.deadline_day_of_month,
      dayOfWeek: deadline.deadline_day_of_week,
      hour: deadline.deadline_hour,
      minute: deadline.deadline_minute,
      month: deadline.deadline_month,
    };

    return `${cronParts.minute} ${cronParts.hour} ${cronParts.dayOfMonth} ${cronParts.month} ${cronParts.dayOfWeek}`;
  },

  buildExpression(cronParts) {
    const { flagEnabled } = useFeatureFlags();
    const seconds = CRON_SECOND_DEFAULT;
    const minutes = cronParts.minuteInterval;
    const hours = this.buildCronHours(cronParts.startTime, cronParts.endTime);
    const daysOfMonth = CRON_ANY_VALUE;
    const month = CRON_ANY_VALUE;
    const daysOfWeek = cronParts.dayOfWeek.length ? toString(sortBy(cronParts.dayOfWeek)) : null;

    /* c8 ignore start */
    return flagEnabled('useFivePartCron')
      ? `${minutes} ${hours} ${daysOfMonth} ${month} ${daysOfWeek}`
      : `${seconds} ${minutes} ${hours} ${daysOfMonth} ${month} ${daysOfWeek}`;
    /* c8 ignore stop */
  },

  // converts '1/5,2,5-7' (hours) to [1, 6, 11, 16, 21, 2, 5, 6, 7]
  getCronPartAllValues(cronPart, cronPartIndex) {
    const { flagEnabled } = useFeatureFlags();

    const partValues = cronPart.split(',');

    const allValues = [];
    partValues.forEach((partValue) => {
      if (partValue.includes('-')) {
        const partDigits = partValue.split('-');
        allValues.push(...range(+partDigits[0], +partDigits[1] + 1));

        return;
      }

      const cronPartMaxValue = flagEnabled('useFivePartCron') ? CRON_PART_MAX_VALUE_NEXT : CRON_PART_MAX_VALUE;
      if (partValue.includes('/')) {
        const partDigits = partValue.split('/');
        const step = +partDigits[1];
        const startFrom = +partDigits[0] | 0;
        allValues.push(...range(startFrom, cronPartMaxValue[cronPartIndex] + 1, step));

        return;
      }

      if (partValue === CRON_ANY_VALUE) {
        allValues.push(...range(0, cronPartMaxValue[cronPartIndex] + 1));

        return;
      }

      const value = +partValue;
      allValues.push(value);
    });

    return allValues;
  },

  getHeatmapData(cron, totalFiles) {
    const { flagEnabled } = useFeatureFlags();

    const cronPartIndex = flagEnabled('useFivePartCron') ? CRON_PART_INDEX_NEXT : CRON_PART_INDEX;
    const cronParts = cron.split(' ');

    cronParts[cronPartIndex.daysOfWeek] = this.mapDaysOfWeek(cronParts[cronPartIndex.daysOfWeek]);
    const hours = cronParts.map(this.getCronPartAllValues)[cronPartIndex.hours];
    const daysOfWeek = cronParts.map(this.getCronPartAllValues)[cronPartIndex.daysOfWeek];

    const possibleTimeValues = hours;

    const daysInWeek = 7;
    const hoursInDay = 24;

    const data = range(daysInWeek).reduce((result, dayIndex) => {
      const group = [];
      const dayOfWeekIncluded = daysOfWeek.includes(dayIndex);

      for (const block in range(hoursInDay)) {
        const value = possibleTimeValues.includes(+block) && dayOfWeekIncluded ? totalFiles : 0;
        group.push(value);
      }

      return {
        ...result,
        [dayIndex]: group,
      };
    }, {});

    return {
      data,
      xAxis: HEATMAP_X_AXIS,
      yAxis: HEATMAP_Y_AXIS,
    };
  },

  isCronMacros(cron) {
    return CRON_SPECIAL_EXPRESSIONS.includes(cron.trim());
  },

  // Validate if the cron will run more than once daily
  isIntraDaySchedule(cron) {
    const { flagEnabled } = useFeatureFlags();
    if (!this.isValid(cron)) return false;
    if (NON_INTRA_DAY_EXPRESSIONS.includes(cron)) return false;

    // trim cron whitespace and take first three cron parts (seconds, minutes, hours) for validation
    const partsTakeCount = flagEnabled('useFivePartCron') ? 2 : 3;
    const cronParts = take(split(cron.trim(), /\s+/), partsTakeCount);

    return !every(cronParts, (cronPart) => isInteger(Number(cronPart)));
  },

  // Display a heatmap if it's less than a week. Remove when all kinds of heatmap are supported
  isNonDisplayableCron(cron) {
    const { flagEnabled } = useFeatureFlags();
    const cronPartIndex = flagEnabled('useFivePartCron') ? CRON_PART_INDEX_NEXT : CRON_PART_INDEX;
    const cronParts = cron.split(' ');
    const dayOfMonth = cronParts[cronPartIndex.dayOfMonth];
    const daysOfWeek = cronParts[cronPartIndex.daysOfWeek];

    return (
      dayOfMonth !== CRON_ANY_VALUE ||
      dayOfMonth !== CRON_ANY_VALUE ||
      (daysOfWeek.includes('L') && daysOfWeek !== 'L') ||
      this.isCronMacros(cron)
    );
  },

  isValid(cron) {
    const { flagEnabled } = useFeatureFlags();

    if (!cron) return false;

    // added validation for cron macros and spring cron that requires minimum 6 parts
    const isCronMacros = this.isCronMacros(cron.trim());
    const requiredCronPartsCount = flagEnabled('useFivePartCron') ? CRONS_PARTS_COUNT_NEXT : CRON_PARTS_COUNT;
    const invalidPartsCount = cron.trim().split(/\s+/).length !== requiredCronPartsCount;

    if (!isCronMacros && invalidPartsCount) return false;

    /* c8 ignore start */
    return flagEnabled('useFivePartCron')
      ? isValidCron(cron, { allowSevenAsSunday: true }) || isCronMacros
      : isValidCron(cron, { allowSevenAsSunday: true, seconds: true }) || isCronMacros;
    /* c8 ignore stop */
  },

  // tue-thu,sun -> 2-4,0
  mapDaysOfWeek(cronDaysOfWeekPart) {
    const { flagEnabled } = useFeatureFlags();
    const cronPartIndex = flagEnabled('useFivePartCron') ? CRON_PART_INDEX_NEXT : CRON_PART_INDEX;
    cronDaysOfWeekPart = cronDaysOfWeekPart.toUpperCase();

    if (cronDaysOfWeekPart === 'L') return CRON_PART_MAX_VALUE[cronPartIndex.daysOfWeek];

    CRON_DAY_VALUES.forEach((dayOfWeek, index) => {
      cronDaysOfWeekPart = cronDaysOfWeekPart.replace(dayOfWeek, index);
    });

    cronDaysOfWeekPart = cronDaysOfWeekPart.replace('7', '0');

    return cronDaysOfWeekPart;
  },

  toBasicFormFormat(expression) {
    const { flagEnabled } = useFeatureFlags();
    const cronPartIndex = flagEnabled('useFivePartCron') ? CRON_PART_INDEX_NEXT : CRON_PART_INDEX;
    const parts = expression.trim().split(/\s+/);
    const dayOfWeekExpression = parts[cronPartIndex.daysOfWeek];
    const hours = parts[cronPartIndex.hours];
    const minutes = parts[cronPartIndex.minutes];
    const dayOfWeek = this.getCronPartAllValues(dayOfWeekExpression, cronPartIndex.daysOfWeek);
    const [startTime, endTime] = hours.includes('-')
      ? hours.split('-').map((time) => parseInt(time, 10))
      : [parseInt(hours, 10), null];

    return {
      dayOfWeek,
      endTime,
      minuteInterval: minutes,
      startTime,
    };
  },

  toString(expression, options = {}, showTimeZone = true, ignoreLength = false) {
    const { flagEnabled } = useFeatureFlags();
    const requiredCronPartsCount = flagEnabled('useFivePartCron') ? CRONS_PARTS_COUNT_NEXT : CRON_PARTS_COUNT;
    if (!expression) return;

    expression = expression.trim();
    const expressionLength = expression.split(' ').length;
    if (!ignoreLength && !this.isCronMacros(expression) && expressionLength !== requiredCronPartsCount) {
      // eslint-disable-next-line no-throw-literal
      throw `Error: Expression has ${expressionLength} part(s). ${requiredCronPartsCount} parts are required.`;
    }

    return this.isCronMacros(expression)
      ? CRON_SPECIAL_DESCRIPTIONS[expression]
      : `${cronstrueToString(expression, options)}${showTimeZone ? ` ${CRON_TIMEZONE}` : ''}`;
  },

  toStringIgnoringLength(expression, options = {}, showTimeZone = true) {
    return this.toString(expression, options, showTimeZone, true);
  },

  toStringWithoutPrefix(...args) {
    return this.toString(...args)
      .replace('At', '')
      .replace('only', '')
      .trim();
  },

  // Validation if a cron can be supported by our cron builder form
  validateCronFormCompatibility(cronExpression) {
    const { flagEnabled } = useFeatureFlags();
    const isFivePartCron = flagEnabled('useFivePartCron');
    const cronParts = cronExpression.trim().split(' ');
    const requiredPartsCount = isFivePartCron ? CRONS_PARTS_COUNT_NEXT : CRON_PARTS_COUNT;
    const cronPartIndex = isFivePartCron ? CRON_PART_INDEX_NEXT : CRON_PART_INDEX;

    // day of week should only include numbers from 0 to 6, with ranges and steps allowed (ex. 1-5, *, 1,2,3,4)
    const dayOfWeekPattern = /^(?:\*|[0-6](?:-[0-6])?(?:\/[1-7])?|[0-6](?:,[0-6])*)$/;
    // Hour field should be a number from 0-23 or specific ranges ex. 3-5
    const hourPattern = /^(?:[01]?\d|2[0-3])(-[01]?\d|2[0-3])?$/;

    if (cronParts.length !== requiredPartsCount) return false;

    const extractParts = () => {
      const parts = {
        dayOfMonth: cronParts[cronPartIndex.dayOfMonth],
        daysOfWeek: cronParts[cronPartIndex.daysOfWeek],
        hours: cronParts[cronPartIndex.hours],
        minutes: cronParts[cronPartIndex.minutes],
        months: cronParts[cronPartIndex.months],
        seconds: isFivePartCron ? null : cronParts[cronPartIndex.seconds],
      };

      return parts;
    };

    const { seconds, minutes, hours, dayOfMonth, months, daysOfWeek } = extractParts();

    const validations = [
      !isFivePartCron && seconds !== '0',
      !['*/30', '0'].includes(minutes),
      !hourPattern.test(hours),
      dayOfMonth !== '*' || months !== '*',
      !dayOfWeekPattern.test(daysOfWeek),
    ];

    return !validations.some(Boolean);
  },
};
